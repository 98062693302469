import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Img from "../components/Img";
import PhotoSection from "../components/PhotoSection";
import FloatingArticle from "../components/FloatingArticle";
import Article from "../components/Article";
import Nav from "../components/Nav";
import Footnote from "../components/Footnote";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Paragraph from "../components/Paragraph";
import ParagraphPull from "../components/ParagraphPull";
import PhotoGallery from "../components/PhotoGallery";

const EstatePage = ({ data }) => (
  <Layout>
    <SEO title="The Estate" />
    <Nav />
    <Section>
      <Article isCentered>
        <H1>The Estate</H1>
        <Paragraph isLarge>
          For over 100 years, Oak Hall has held vigil, perched high on a hill above Penobscot Bay.
          Built as a summer mansion in the twilight of the Gilded Age, it has served as a private
          residence to a succession of families great and small.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Img
        imgSrc={data.engravingFront.childImageSharp.fluid}
        imgAlt="Engraving of Oak Hall by Steven Noble"
        isTransparent
      />
    </Section>
    <Section>
      <Article>
        <Paragraph>
          Built in 1912-1914 by Chicago outfit Marshall &amp; Fox, Oak Hall is a very ambitious
          example of Colonial Revival architecture, featuring over 30,000 square feet of living
          space, and nearly fifty rooms. When built, Oak Hall featured every modern convenience,
          including a two storey Aeloian Player Organ. The main section is composed of a long and
          broad pitched-roofed rectangle of three storeys, with full two-storey colonnaded porticos
          on both sides. The southwest colonnade, facing a circular drive, consists of eight square
          columns with entablature, and is flanked by prominent chimneys.
        </Paragraph>
        <Paragraph>
          The northeast collonade faces the Bay and overlooks the sprawling and expansive grounds,
          framed by stately oaks and anchored by an immense, glacial boulder half way up the
          meandering drive. Each wing is framed by two-storey wings, and bookended by solariums
          featuring floor to ceiling windows on three sides. The exterior of Oak Hall has changed
          little over the last 100 years, and the interior would be as recognizable today as it was
          in the summer of 1914 when Ira and Annie Cobe first took residence.
        </Paragraph>
      </Article>
    </Section>
    <PhotoSection
      background={data.backgroundWinter.childImageSharp.fluid.src}
      backgroundPosition="bottom left"
    >
      <FloatingArticle>
        <H2>Winter Wonderland</H2>
        <Paragraph>
          Much of the Maine coast is home to famed summer beach communities. Bayside, established as
          a church camp in 1849, is no exception. While Oak Hall was designed as a summer ‘cottage’,
          it was built to withstand the rigors of a Maine winter and serves as a cosy retreat all
          year round.
        </Paragraph>
        <Paragraph>
          In the winter, the gardens give way a snow covered expanse, making for bright and cheery
          grounds that are home to deer, moose, and flocks of turkey.
        </Paragraph>
        <Footnote>
          In addition to a state of the art heating system, Oak Hall features over a dozen
          fireplaces, many of which are ornamented with exquisite Wedgwood detailing.
        </Footnote>
      </FloatingArticle>
    </PhotoSection>
    <Section>
      <Article>
        <H2>Built to Last</H2>
        <ParagraphPull orientation="RIGHT">
          <Img imgSrc={data.rearEntrance.childImageSharp.fluid} imgAlt="Oak Hall entrance" />
          <Footnote>
            The collonaded entry off the circular drive through which guests first enter Oak Hall.
          </Footnote>
        </ParagraphPull>
        <Paragraph isLarge>
          Oak Hall was built to last for generations. In 1912, Chicago outfit Marshall &amp; Fox was
          hired to design and oversee construction which took over two years to complete.
        </Paragraph>
        <Paragraph>
          Experts in development of early high-rises, and known for landmarks like the Drake Hotel
          and the Steger Building, Marshall &amp; Fox approached Oak Hall much as they would have
          approached a large scale commercial building project Chicago.
        </Paragraph>
        <Paragraph>
          Oak Hall has a steel superstructure and poured concrete walls and floors, all covered in
          fireproof terra-cotta clay tile. It is wrapped in locally produced brick, roofed in Monson
          slate, Oak Hall, with marble lintels over windows and doors, and expansive Italian marble
          decks on both faces. No expense was spared in its construction.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Article>
        <H2>Life Inside</H2>
        <Paragraph>
          The interior of Oak Hall is elaborately ornamented with fine plasterwork, richly carved
          woods, and gilt French paneling or boiserie in all of the principal rooms. The wings of
          the first floor are connected by the long gallery with the dining room on one end, and the
          living room and music room on the other.
        </Paragraph>
      </Article>
      <PhotoGallery items={data.gallery.edges}></PhotoGallery>
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    gallery: allFile(filter: { absolutePath: { regex: "/estate/gallery/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2048) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    engravingFront: file(absolutePath: {regex: "/\\/estate\\/engraving-front\\.png/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    backgroundWinter: file(absolutePath: {regex: "/\\/estate\\/front-winter\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rearEntrance: file(absolutePath: {regex: "/\\/estate\\/rear-entrance\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default EstatePage;
